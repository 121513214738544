body {
  overflow-x: hidden;
}

.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limits to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
